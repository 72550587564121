import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { AppBar, Toolbar, Typography, IconButton, Box, CssBaseline } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import Login from './components/Login';
import Dashboard from './components/Dashboard';

import AddTourType from './components/AddTourType';
import EditTourType from './components/EditTourType';
import ManageSpecialTourType from './components/ManageTourTypeSpecial';
import AddSpecialTourType from './components/AddTourTypeSpecial';
import EditSpecialTourType from './components/EditTourTypeSpecial';
import ManageDestination from './components/ManageDestination';
import AddDestination from './components/AddDestination';
import EditDestination from './components/EditDestination';
import ManageItinerary from './components/ManageItinerary';
import AddItinerary from './components/AddItinerary';
import EditItinerary from './components/EditItinerary';
import ManageGallery from './components/ManageGallery';
import AddGallery from './components/AddGallery';
import EditGallery from './components/EditGallery';
import Sidebar from './components/Sidebar';
import ManageDiscovery from './components/ManageDiscovery';
import AddEditDiscovery from './components/AddEditDiscovery';
import ManagePartners from './components/ManagePartner';
import AddEditPartner from './components/AddEditPartner';
import ManageLocations from './components/ManageLocation';
import AddEditLocation from './components/AddEditLocation';
import ManageCoreCompetency from './components/ManageCoreCompetency';
import AddEditCoreCompetency from './components/AddEditCoreCompetency';
import ManageContinent from './components/ManageContinent';
import AddEditContinent from './components/AddEditContinent';
import ManageTourType from './components/ManageTourType';
import AddEditTourType from './components/AddEditTourType';
import ManageSpecialFeatures from './components/SpecialFeatureList';
import AddEditSpecialFeature from './components/AddEditSpecialFeature';
import TestimonialList from './components/TestimonialList';
import AddEditTestimonial from './components/AddEditTestimonial';
import HomeMantleList from './components/HomeMantleList';
import AddEditMantle from './components/AddEditMantle';
import ContactList from './components/ContactList';
import UserList from './components/userlist';
import ManageAccrediation from './components/ManageAccrediation';
import AddEditAccrediation from './components/AddEditAccrediation';
import TravelList from './components/travel_data_list';
const drawerWidth = 100;
const collapsedWidth = 60;

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(
    localStorage.getItem('isAuthenticated') === 'true'
  );
  const [sidebarOpen, setSidebarOpen] = useState(true);

  useEffect(() => {
    localStorage.setItem('isAuthenticated', isAuthenticated);
  }, [isAuthenticated]);

  const handleLogin = () => {
    setIsAuthenticated(true);
  };

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };

  const closeSidebar = () => {
    setSidebarOpen(false);
  };

  const handleLogout = () => {
    setIsAuthenticated(false);
    closeSidebar();
  };

  return (
    <Router>
      <Box sx={{ display: 'flex', height: '100vh', width: '100vw', overflow: 'hidden' }}>
        <CssBaseline />
        {isAuthenticated && (
          <>
            <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}>
              <Toolbar>
                <IconButton edge="start" color="inherit" aria-label="menu" onClick={toggleSidebar}>
                  <MenuIcon />
                </IconButton>
                <Typography variant="h6" noWrap component="div">
                  My Admin Panel
                </Typography>
              </Toolbar>
            </AppBar>
            <Sidebar open={sidebarOpen} onClose={handleLogout} onToggle={toggleSidebar} collapsed={!sidebarOpen} />
          </>
        )}
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            p: 3,
            transition: 'margin 0.3s',
            marginLeft: isAuthenticated && sidebarOpen ? `${drawerWidth}px` : `${collapsedWidth}px`,
            width: `calc(100vw - ${isAuthenticated && sidebarOpen ? drawerWidth : collapsedWidth}px)`,
            overflowX: 'hidden',
          }}
        >
          <Toolbar />
          <Routes>
            <Route path="/login" element={<Login onLogin={handleLogin} />} />
            <Route path="/" element={isAuthenticated ? <Dashboard /> : <Login onLogin={handleLogin} />} />
            <Route path="/manage-tour-type" element={<ManageTourType />} />
            {/* <Route path="/manage-tour-type/add" element={<AddTourType />} />
            <Route path="/manage-tour-type/edit/:id" element={<EditTourType />} />*/}
            <Route path="/manage-special-tour-type" element={<ManageSpecialTourType />} /> 
            <Route path="/manage-special-tour-type/add" element={<AddSpecialTourType />} />
            <Route path="/manage-special-tour-type/edit/:id" element={<EditSpecialTourType />} />
            <Route path="/manage-destination/:tourTypeId" element={<ManageDestination />} />
            <Route path="/manage-destination/add/:tourTypeId" element={<AddDestination />} />
            <Route path="/manage-destination/edit/:id" element={<EditDestination />} />
            <Route path="/manage-destination/itineraries/:destinations_id" element={<ManageItinerary />} />
            <Route path="/manage-itinerary/add/:destinations_id" element={<AddItinerary />} />
            <Route path="/manage-itinerary/edit/:id" element={<EditItinerary />} />
            <Route path="/manage-destination/gallery/:destinations_id" element={<ManageGallery />} />
            <Route path="/manage-gallery/add/:destinations_id" element={<AddGallery />} />
            <Route path="/manage-gallery/edit/:id" element={<EditGallery />} />
            <Route path='/manage-discovery' element={<ManageDiscovery />} />
            <Route path='/discovery/add' element={<AddEditDiscovery />} />
            <Route path='/discovery/edit/:id' element={<AddEditDiscovery />} />
            <Route path='/manage-partners' element={<ManagePartners />} />
          <Route path='/add-partner' element={<AddEditPartner />} />
          <Route path='/edit-partner/:id' element={<AddEditPartner />} />
          <Route path='/manage-locations' element={<ManageLocations />} />
          <Route path='/add-location' element={<AddEditLocation />} />
          <Route path='/edit-location/:id' element={<AddEditLocation />} />
          <Route path='/manage-core-competency' element={<ManageCoreCompetency />} />
          <Route path='/add-core-competency' element={<AddEditCoreCompetency />} />
          <Route path='/edit-core-competency/:id' element={<AddEditCoreCompetency />} />
          <Route path='/manage-accrediation' element={<ManageAccrediation />} />
          <Route path='/add-accrediation' element={<AddEditAccrediation />} />
          <Route path='/edit-accrediation/:id' element={<AddEditAccrediation />} />
          <Route path="/manage-continents" element={<ManageContinent />} />
          <Route path="/manage-continents/add" element={<AddEditContinent />} />
          <Route path="/manage-continents/edit/:id" element={<AddEditContinent />} />
          <Route path="/manage-tour-type/:continentId" element={<ManageTourType />} />
          <Route path="/manage-tour-type/add/:continentId" element={<AddEditTourType />} />
          <Route path="/manage-tour-type/edit/:id/:continentId" element={<AddEditTourType />} />

          <Route path="/manage-special-features/:tourTypeId" element={<ManageSpecialFeatures />} />
        <Route path="/add-special-feature/:tourTypeId" element={<AddEditSpecialFeature />} />
        <Route path="/edit-special-feature/:id/:tourTypeId" element={<AddEditSpecialFeature />} />
        <Route path="/testimonials" element={<TestimonialList />} />
        <Route path="/add-testimonial" element={<AddEditTestimonial />} />
        <Route path="/edit-testimonial/:id" element={<AddEditTestimonial />} />
        <Route path="/home-mantle" element={<HomeMantleList />} />
        <Route path="/add-home-mantle" element={<AddEditMantle />} />
        <Route path="/edit-home-mantle/:id" element={<AddEditMantle />} />
        <Route path="/contact" element={<ContactList />} />
        <Route path="/user" element={<UserList />} />
        <Route path="/travel" element={<TravelList />} />

          </Routes>
        </Box>
      </Box>
    </Router>
  );
};

export default App;
