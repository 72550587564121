import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import {
  Box,
  Button,
  TextField,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  FormLabel,
  Breadcrumbs,
  Checkbox
} from '@mui/material';

const BASE_MEDIA_URL = 'https://aaryanholidays.in/express/uploads/';

const AddEditMantle = () => {
  const { id } = useParams();
  const [alt, setAlt] = useState('');
  const [type, setType] = useState('img');
  const [heading, setHeading] = useState('');
  const [subHeading, setSubHeading] = useState('');
  const [linkTo, setLinkTo] = useState('');
  const [bannerFor, setBannerFor] = useState(0);
  const [mantleOrder, setMantleOrder] = useState(0);
  const [isActive, setIsActive] = useState(true);
  const [currentBannerSrc, setCurrentBannerSrc] = useState('');
  const [newBannerSrc, setNewBannerSrc] = useState(null);
  const [existingData, setExistingData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      axios.get(`https://aaryanholidays.in/backend/home-mantle/${id}`)
        .then(response => {
          const data = response.data;
          setAlt(data.alt);
          setType(data.type);
          setHeading(data.heading);
          setSubHeading(data.sub_heading);
          setLinkTo(data.link_to);
          setBannerFor(data.banner_for);
          setMantleOrder(data.mantle_order);
          setIsActive(data.is_active_id === 1);
          setCurrentBannerSrc(data.src);
        })
        .catch(error => console.error('Error fetching the mantle data:', error));
    }
  }, [id]);

  const handleFileChange = (e) => {
    setNewBannerSrc(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('alt', alt);
    formData.append('type', type);
    formData.append('heading', heading);
    formData.append('sub_heading', subHeading);
    formData.append('link_to', linkTo);
    formData.append('banner_for', bannerFor);
    formData.append('mantle_order', mantleOrder);
    formData.append('is_active_id', isActive ? 1 : 0);
    formData.append('src', newBannerSrc);

    try {
      if (id) {
        await axios.put(`https://aaryanholidays.in/backend/home-mantle/${id}`, formData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        });
      } else {
        await axios.post('https://aaryanholidays.in/backend/home-mantle', formData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        });
      }
      navigate('/home-mantle');
    } catch (error) {
      console.error('There was an error saving the mantle!', error);
    }
  };

  return (
    <Box sx={{ p: 3 }}>
      <Breadcrumbs aria-label="breadcrumb">
        <Link to="/manage-home-mantles">Home Mantles</Link>
        <Typography color="textPrimary">{id ? 'Edit Mantle' : 'Add New Mantle'}</Typography>
      </Breadcrumbs>
      <Typography variant="h4" gutterBottom>{id ? 'Edit Mantle' : 'Add New Mantle'}</Typography>
      <form onSubmit={handleSubmit}>
        <TextField
          label="Alt Text"
          fullWidth
          margin="normal"
          value={alt}
          onChange={(e) => setAlt(e.target.value)}
        />
        <FormControl component="fieldset" margin="normal">
          <FormLabel component="legend">Type</FormLabel>
          <RadioGroup row value={type} onChange={(e) => setType(e.target.value)}>
            <FormControlLabel value="img" control={<Radio />} label="Image" />
            <FormControlLabel value="video" control={<Radio />} label="Video" />
          </RadioGroup>
        </FormControl>
        <TextField
          label="Heading"
          fullWidth
          margin="normal"
          value={heading}
          onChange={(e) => setHeading(e.target.value)}
        />
        <TextField
          label="Sub Heading"
          fullWidth
          margin="normal"
          value={subHeading}
          onChange={(e) => setSubHeading(e.target.value)}
        />
        <TextField
          label="Link To"
          fullWidth
          margin="normal"
          value={linkTo}
          onChange={(e) => setLinkTo(e.target.value)}
        />
        <TextField
          label="Banner For"
          fullWidth
          margin="normal"
          type="number"
          value={bannerFor}
          onChange={(e) => setBannerFor(e.target.value)}
        />
        <TextField
          label="Mantle Order"
          fullWidth
          margin="normal"
          type="number"
          value={mantleOrder}
          onChange={(e) => setMantleOrder(e.target.value)}
        />
        <FormControl component="fieldset" margin="normal">
          <FormControlLabel
            control={
              <Checkbox
                checked={isActive}
                onChange={() => setIsActive(!isActive)}
              />
            }
            label="Is Active"
          />
        </FormControl>
        <FormControl fullWidth margin="normal">
          <FormLabel>Upload Banner</FormLabel>
          <input
            type="file"
            onChange={handleFileChange}
            accept={type === 'image' ? 'image/*' : 'video/*'}
          />
          {currentBannerSrc && type === 'img' && (
            <img
              src={`${BASE_MEDIA_URL}${currentBannerSrc}`}
              alt="Current Banner"
              style={{ marginTop: '10px', maxWidth: '200px', maxHeight: '200px' }}
            />
          )}
          {currentBannerSrc && type === 'video' && (
            <video
              src={`${BASE_MEDIA_URL}${currentBannerSrc}`}
              controls
              style={{ marginTop: '10px', maxWidth: '200px', maxHeight: '200px' }}
            />
          )}
          {newBannerSrc && type === 'img' && (
            <img
              src={URL.createObjectURL(newBannerSrc)}
              alt="New Banner"
              style={{ marginTop: '10px', maxWidth: '200px', maxHeight: '200px' }}
            />
          )}
          {newBannerSrc && type === 'video' && (
            <video
              src={URL.createObjectURL(newBannerSrc)}
              controls
              style={{ marginTop: '10px', maxWidth: '200px', maxHeight: '200px' }}
            />
          )}
        </FormControl>
        <Button type="submit" variant="contained" color="primary">Save</Button>
      </form>
    </Box>
  );
};

export default AddEditMantle;
